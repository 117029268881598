import React from "react"
import { Empty, Icon, Table, Tooltip } from "antd"
import moment from "moment-timezone"
import { useIntl } from "gatsby-plugin-intl"
import { v4 as uuidV4 } from "uuid"

import { formatIngestDate } from "../services/ingest"

const IngestStatisticsTable = ({ isRealTimeIngest, statistics }) => {
  const intl = useIntl()

  const renderEventsStatisticsCell = (
    processed,
    warnings,
    warningsByMessage = []
  ) => {
    return (
      <>
        {typeof processed === "number" ? (
          <p className="ingest-statistics-cells">
            {intl.formatMessage({ id: "processed-events" })}: <b>{processed}</b>
          </p>
        ) : null}
        {typeof warnings === "number" ? (
          <p className="ingest-statistics-cells">
            {intl.formatMessage({ id: "warning-graveyard" })}: <b>{warnings}</b>
          </p>
        ) : null}
        {Array.isArray(warningsByMessage) && warningsByMessage.length > 0 ? (
          <ul className="ingest-statistics-warnings-by-message">
            {warningsByMessage.map(w => (
              <li className="ingest-statistics-warnings-by-message-item">
                {w}
              </li>
            ))}
          </ul>
        ) : null}
      </>
    )
  }

  const REAL_TIME_COLUMNS = [
    {
      title: intl.formatMessage({ id: "activation-date" }),
      dataIndex: "activationDate",
      key: "activationDate",
      width: "15%",
      render: formatIngestDate,
      sorter: (a, b) =>
        moment(a.activationDate).isBefore(moment(b.activationDate)) ? 1 : -1,
    },
    {
      title: intl.formatMessage({ id: "deactivation-date" }),
      dataIndex: "deactivationDate",
      key: "deactivationDate",
      width: "15%",
      render: formatIngestDate,
      sorter: (a, b) =>
        moment(a.deactivationDate).isBefore(moment(b.deactivationDate))
          ? 1
          : -1,
    },
    {
      title: intl.formatMessage({ id: "total-interval" }),
      key: "interval_total",
      width: "17.5%",
      render: (text, record = {}) => {
        const { processedIntervalTotal, warningsIntervalTotal } = record
        return renderEventsStatisticsCell(
          processedIntervalTotal,
          warningsIntervalTotal
        )
      },
    },
    {
      title: (
        <span>
          <span>{intl.formatMessage({ id: "last-30-mins" })}</span>
          <Tooltip title={intl.formatMessage({ id: "last-30-mins-tooltip" })}>
            <Icon
              type="info-circle"
              style={{ marginLeft: "5px", color: "#868d96" }}
            />
          </Tooltip>
        </span>
      ),
      dataIndex: "thirtyMinutesAgg",
      key: "thirtyMinutesAgg",
      width: "17.5%",
      render: (text, { thirtyMinutesAgg = {} }) => {
        const { processed, warnings, warningsByMessage = [] } = thirtyMinutesAgg
        return renderEventsStatisticsCell(
          processed,
          warnings,
          warningsByMessage
        )
      },
    },
    {
      title: (
        <span>
          <span>{intl.formatMessage({ id: "last-60-mins" })}</span>
          <Tooltip title={intl.formatMessage({ id: "last-60-mins-tooltip" })}>
            <Icon
              type="info-circle"
              style={{ marginLeft: "5px", color: "#868d96" }}
            />
          </Tooltip>
        </span>
      ),
      dataIndex: "sixtyMinutesAgg",
      key: "sixtyMinutesAgg",
      width: "17.5%",
      render: (text, { sixtyMinutesAgg = {} }) => {
        const { processed, warnings, warningsByMessage = [] } = sixtyMinutesAgg
        return renderEventsStatisticsCell(
          processed,
          warnings,
          warningsByMessage
        )
      },
    },
    {
      title: (
        <span>
          <span>{intl.formatMessage({ id: "last-day" })}</span>
          <Tooltip title={intl.formatMessage({ id: "last-day-tooltip" })}>
            <Icon
              type="info-circle"
              style={{ marginLeft: "5px", color: "#868d96" }}
            />
          </Tooltip>
        </span>
      ),
      dataIndex: "dayAgg",
      key: "dayAgg",
      width: "17.5%",
      render: (text, { dayAgg = {} }) => {
        const { processed, warnings, warningsByMessage = [] } = dayAgg
        return renderEventsStatisticsCell(
          processed,
          warnings,
          warningsByMessage
        )
      },
    },
  ]

  const BATCH_COLUMNS = [
    {
      title: intl.formatMessage({ id: "file" }),
      dataIndex: "filename",
      key: "filename",
      width: "20%",
      sorter: (a, b) =>
        a.filename > b.filename ? 1 : a.filename < b.filename ? -1 : 0,
    },
    {
      title: intl.formatMessage({ id: "started-at" }),
      dataIndex: "startedAt",
      key: "startedAt",
      width: "20%",
      render: formatIngestDate,
      sorter: (a, b) =>
        moment(a.startedAt).isBefore(moment(b.startedAt)) ? 1 : -1,
    },
    {
      title: intl.formatMessage({ id: "ended-at" }),
      dataIndex: "finishedAt",
      key: "finishedAt",
      width: "20%",
      render: formatIngestDate,
      sorter: (a, b) =>
        moment(a.finishedAt).isBefore(moment(b.finishedAt)) ? 1 : -1,
    },
    {
      title: intl.formatMessage({ id: "duration" }),
      dataIndex: "duration",
      key: "duration",
      width: "15%",
      sorter: (a, b) =>
        a.duration > b.duration ? 1 : a.duration < b.duration ? -1 : 0,
    },
    {
      title: intl.formatMessage({ id: "total-processed-rows" }),
      dataIndex: "",
      key: "dataTax",
      width: "30%",
      render: (text, record) => {
        const {
          processed,
          totalWarningMessages,
          warningsByMessage = [],
        } = record
        return renderEventsStatisticsCell(
          processed,
          totalWarningMessages,
          warningsByMessage
        )
      },
    },
  ]

  const columns = isRealTimeIngest ? REAL_TIME_COLUMNS : BATCH_COLUMNS

  return Array.isArray(statistics) && statistics.length > 0 ? (
    <Table
      rowKey={() => uuidV4()}
      className="ingests-table"
      rowClassName={(record, index) => (index % 2 === 0 ? "pair" : "even")}
      showHeader={true}
      dataSource={statistics}
      columns={columns}
    />
  ) : (
    <Empty
      description={intl.formatMessage({
        id: "no-ingestion-statistics-yet",
      })}
    />
  )
}

export default IngestStatisticsTable
