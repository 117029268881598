import React from "react"
import _ from "lodash"
import ReactJson from "react-json-view"
import { useIntl } from "gatsby-plugin-intl"

import { formatIngestDate } from "../services/ingest"
import { v4 as uuidV4 } from "uuid"

const IngestDetailTable = ({ ingest, credentials }) => {
  const intl = useIntl()

  const COMMON_FIELDS = [
    {
      title: intl.formatMessage({ id: "name" }),
      dataIndex: "name",
    },
    {
      title: intl.formatMessage({ id: "models-ingestion-alias" }),
      dataIndex: "alias",
    },
    {
      title: intl.formatMessage({ id: "created-by" }),
      dataIndex: "createdBy",
    },
    {
      title: intl.formatMessage({ id: "creation-date" }),
      dataIndex: "createdAt",
      transform: formatIngestDate,
    },
    {
      title: intl.formatMessage({ id: "description" }),
      dataIndex: "description",
    },
  ]

  const renderDownloadLinkCell = value => {
    return (
      <div style={{ padding: "12px 24px" }}>
        <a href={value}>{intl.formatMessage({ id: "download" })}</a>
      </div>
    )
  }

  const RT_FIELDS = [
    {
      title: intl.formatMessage({ id: "certificate" }),
      dataIndex: "s3-certificates-params.certificate_pem_file",
      render: renderDownloadLinkCell,
    },
    {
      title: intl.formatMessage({ id: "public-key" }),
      dataIndex: "s3-certificates-params.public_key",
      render: renderDownloadLinkCell,
    },
    {
      title: intl.formatMessage({ id: "private-key" }),
      dataIndex: "s3-certificates-params.private_key",
      render: renderDownloadLinkCell,
    },
  ]

  const NRT_FIELDS = [
    {
      title: intl.formatMessage({ id: "broker-user" }),
      dataIndex: "BrokerUser",
    },
    {
      title: intl.formatMessage({ id: "broker-password" }),
      dataIndex: "BrokerPass",
    },
    {
      title: intl.formatMessage({ id: "broker-queue" }),
      dataIndex: "BrokerQueue",
    },
    {
      title: intl.formatMessage({ id: "broker-mqtt-endpoint" }),
      dataIndex: "MqttEndpoints",
    },
  ]

  const BATCH_FIELDS = [
    {
      title: intl.formatMessage({ id: "credentials" }),
      dataIndex: "credential_url",
      render: renderDownloadLinkCell,
    },
    {
      title: intl.formatMessage({ id: "sftp-endpoint" }),
      dataIndex: "sftp_endpoint",
    },
    {
      title: intl.formatMessage({ id: "sftp-user" }),
      dataIndex: "sftp_user",
    },
  ]

  const getFieldsToDisplay = ingest => {
    const type = ingest && ingest.type
    switch (type) {
      case "REAL_TIME":
        return [...COMMON_FIELDS, ...RT_FIELDS]
      case "NEAR_REAL_TIME":
        return [...COMMON_FIELDS, ...NRT_FIELDS]
      case "BATCH":
      case "METADATA":
        return [...COMMON_FIELDS, ...BATCH_FIELDS]
      default:
        return []
    }
  }

  const renderDetailFieldValue = field => {
    const { transform, dataIndex, render } = field
    let value = _.get(Object.assign({}, ingest, credentials || {}), dataIndex)
    value = typeof transform === "function" ? transform(value) : value
    if (value === null || value === undefined) {
      return null
    } else if (typeof render === "function") {
      return render(value)
    } else {
      return <span>{value}</span>
    }
  }

  const shouldRenderField = ({ dataIndex = "" }) => {
    const value = _.get(
      Object.assign({}, ingest, credentials || {}),
      dataIndex,
      null
    )
    return value !== null
  }

  return (
    <div className="ingest-detail-container">
      <div className="ingest-detail-fields">
        {getFieldsToDisplay(ingest || {}).map(field => {
          if (shouldRenderField(field)) {
            return (
              <div className="ingest-detail-row" key={uuidV4()}>
                <div className="ingest-detail-row__title">
                  <span>{field.title}</span>
                </div>
                <div className="ingest-detail-row__value">
                  {renderDetailFieldValue(field)}
                </div>
              </div>
            )
          } else {
            return null
          }
        })}
      </div>
      <div className="ingest-detail-detail">
        <p className="ingest-detail-detail-header">
          {intl.formatMessage({ id: "raw-ingestion-detail" })}
        </p>
        <ReactJson
          src={ingest.__raw}
          name={false}
          sortKeys={true}
          displayObjectSize={false}
          displayDataTypes={false}
          enableClipboard={false}
          style={{
            border: "1px solid #dadee5",
            textAlign: "left",
            maxHeight: "375px",
            overflow: "auto",
          }}
        />
      </div>
    </div>
  )
}

export default IngestDetailTable
