import React from "react"
import moment from "moment-timezone"
import {
  Button,
  Col,
  Form,
  Icon,
  Input,
  message,
  Modal,
  Row,
  Select,
  Spin,
  Table,
  Tabs,
  Tooltip,
} from "antd"
import { injectIntl, navigate } from "gatsby-plugin-intl"
import authFetch from "../../services/network"
import CustomBreadcrumb from "../../components/commonBreadcrumb"
import IngestTag from "../../components/ingestTag"
import IngestStatus from "../../components/ingestStatus"
import withAuth from "../../components/withAuthHoc"
import Template from "../../layouts/base"
import IngestStatisticsTable from "../../components/ingestStatisticsTable"
import IngestDetailTable from "../../components/IngestDetailTable"
import LocalizedAniLink from "../../components/localizedAniLink"
import {
  formatIngestDate,
  getIngest,
  ingestIsActive,
  ingestIsCreating,
} from "../../services/ingest"
import IngestActionsCell from "../../components/ingestActionsCell"
import { checkPermissions } from "../../services/auth/permissions/permissions"
import { INGEST_TYPES } from "../../services/constants"

const { Search } = Input
const { TabPane } = Tabs
const { confirm, warning } = Modal

const ONE_MIN_IN_MS = 60000

class IngestDetail extends React.Component {
  static _isEmptyObject(obj) {
    return (
      obj &&
      typeof obj === "object" &&
      obj.constructor === Object &&
      Object.keys(obj).length === 0
    )
  }

  static _ingestIsBatchType(ingest) {
    return ingest && ingest.type === "BATCH"
  }

  static _ingestIsMetadataType(ingest) {
    return ingest && ingest.type === "METADATA"
  }

  constructor(props) {
    super(props)

    this.state = {
      ingest: {},
      ingestIdUrlParam:
        props.location && props.location.pathname.split("/").pop(),
      credentials: {},
      statistics: null,
      loading: true,
      lastUpdate: null,
      searchValue: "",
      linkedWithIngestActive: null,
    }

    this.interval = null
    this.controller = null

    this._filterStatistics = this._filterStatistics.bind(this)
    this._renderTags = this._renderTags.bind(this)
    this._onSearchInputChange = this._onSearchInputChange.bind(this)
    this._navigateToIngestVersion = this._navigateToIngestVersion.bind(this)
    this._createNewIngestVersion = this._createNewIngestVersion.bind(this)
    this._createNewDataSource = this._createNewDataSource.bind(this)
    this._changeIngestStatus = this._changeIngestStatus.bind(this)
    this._onDeleteIngestClick = this._onDeleteIngestClick.bind(this)
    this._shouldLeaveStatisticsOut = this._shouldLeaveStatisticsOut.bind(this)
    this._getModalContent = this._getModalContent.bind(this)
    this._deleteIngestOkCallback = this._deleteIngestOkCallback.bind(this)
    this._deleteIngestErrorCallback = this._deleteIngestErrorCallback.bind(this)
    this._onDeleteIngestCancel = this._onDeleteIngestCancel.bind(this)
    this._onDeleteIngestConfirm = this._onDeleteIngestConfirm.bind(this)
  }

  componentDidMount() {
    if (this.state.ingestIdUrlParam) {
      this._fetchIngest()
        .then(_ =>
          Promise.all([this._fetchStatistics(), this._fetchCredentials()])
        )
        .then(() => this._fetchIngestPeriodically())
    } else {
      message.error(
        this.props.intl.formatMessage({
          id: "page-ingestion-load-ingestion-ko",
        })
      )
      console.error('[ERROR]: No "id" retrieved from URL in order to query it.')
    }
  }

  componentWillUnmount() {
    this._clearInterval()
  }

  get RT_HEADER_COLUMNS() {
    const _thatIntl = this.props.intl
    let columns = [
      {
        title: _thatIntl.formatMessage({ id: "status" }),
        dataIndex: "status",
        key: "status",
        width: 80,
        render: (text, ingest) => {
          const [alias, version] =
            typeof ingest.linked_with === "string" &&
            ingest.linked_with.split(":")

          return (
            <div style={{ display: "flex" }}>
              <IngestStatus ingest={ingest} />
              {ingest.linked_with ? (
                <LocalizedAniLink
                  style={{ marginLeft: 20 }}
                  to={`/ingest/${ingest.linked_with}`}
                >
                  <Tooltip
                    title={`${_thatIntl.formatMessage({
                      id: "page-ingestions-ingestion-combined-label",
                    })}: ${alias} ${version ? `:${version}` : ""}`}
                  >
                    <Icon
                      type="link"
                      size="large"
                      style={{ cursor: "pointer", fontSize: "15px" }}
                    />
                  </Tooltip>
                </LocalizedAniLink>
              ) : null}
            </div>
          )
        },
        ellipsis: true,
      },
      {
        title: _thatIntl.formatMessage({ id: "models-ingestion-alias" }),
        dataIndex: "alias",
        key: "alias",
        width: "15%",
        ellipsis: true,
      },
      {
        title: _thatIntl.formatMessage({ id: "version" }),
        dataIndex: "version",
        key: "version",
        width: "10%",
        ellipsis: true,
      },
      {
        title: _thatIntl.formatMessage({ id: "type" }),
        dataIndex: "type",
        key: "type",
        width: "10%",
      },
      {
        title: _thatIntl.formatMessage({ id: "created-at" }),
        dataIndex: "createdAt",
        key: "createdAt",
        width: "15%",
        render: formatIngestDate,
      },
    ]

    if (this.state.ingest && !this.state.ingest.linked_alias) {
      columns = columns.concat([
        {
          title: _thatIntl.formatMessage({ id: "total-stored-events" }),
          dataIndex: "rows",
          key: "rows",
          width: "15%",
          render: rows => (rows && rows.toLocaleString()) || 0,
        },
        {
          title: _thatIntl.formatMessage({ id: "transfer-rate" }),
          dataIndex: "rateSize",
          key: "rateSize",
          width: "15%",
          //rateSize is in bytes and we handle them as KB
          render: rateSize =>
            typeof rateSize === "number"
              ? `${Math.round((rateSize / 1000 + Number.EPSILON) * 100) /
                  100} KB/s`
              : "--",
        },
      ])
    }

    return columns.concat([
      {
        title: _thatIntl.formatMessage({ id: "tags" }),
        dataIndex: "tags",
        key: "tags",
        width: "20%",
        render: tags => this._renderTags(tags),
      },
      {
        title: _thatIntl.formatMessage({ id: "actions" }),
        dataIndex: "actions",
        key: "actions",
        width: 120,
        render: (value, ingest) => {
          return (
            <IngestActionsCell
              statusChangeDisabled={
                ingestIsCreating(ingest) ||
                !checkPermissions(["status:write"]) ||
                (this.state.ingest &&
                  this.state.ingest.linked_with &&
                  this.state.linkedWithIngestActive === null)
              }
              deleteDisabled={
                ingestIsCreating(ingest) ||
                !checkPermissions(["ingest:delete"]) ||
                (this.state.ingest &&
                  this.state.ingest.linked_with &&
                  this.state.linkedWithIngestActive === null)
              }
              onDeleteClick={this._onDeleteIngestClick}
              onStatusChange={this._changeIngestStatus}
              active={ingestIsActive(ingest)}
            />
          )
        },
      },
    ])
  }

  get BATCH_HEADER_COLUMNS() {
    const _thatIntl = this.props.intl
    let columns = [
      {
        title: _thatIntl.formatMessage({ id: "status" }),
        dataIndex: "status",
        key: "status",
        width: 80,
        render: (text, ingest) => {
          const [alias, version] =
            typeof ingest.linked_with === "string" &&
            ingest.linked_with.split(":")

          return (
            <div style={{ display: "flex" }}>
              <IngestStatus ingest={ingest} />
              {ingest.linked_with ? (
                <LocalizedAniLink
                  style={{ marginLeft: 20 }}
                  to={`/ingest/${ingest.linked_with}`}
                >
                  <Tooltip
                    title={`${_thatIntl.formatMessage({
                      id: "page-ingestions-ingestion-combined-label",
                    })}: ${alias} ${version ? `:${version}` : ""}`}
                  >
                    <Icon
                      type="link"
                      size="large"
                      style={{ cursor: "pointer", fontSize: "15px" }}
                    />
                  </Tooltip>
                </LocalizedAniLink>
              ) : null}
            </div>
          )
        },
        ellipsis: true,
      },
      {
        title: _thatIntl.formatMessage({ id: "models-ingestion-alias" }),
        dataIndex: "alias",
        key: "alias",
        width: "15%",
        ellipsis: true,
      },
      {
        title: _thatIntl.formatMessage({ id: "version" }),
        dataIndex: "version",
        key: "version",
        width: "15%",
        ellipsis: true,
      },
      {
        title: _thatIntl.formatMessage({ id: "type" }),
        dataIndex: "type",
        key: "type",
        width: "15%",
      },
      {
        title: _thatIntl.formatMessage({ id: "created-at" }),
        dataIndex: "createdAt",
        key: "createdAt",
        width: "15%",
        render: formatIngestDate,
      },
    ]

    if (this.state.ingest && !this.state.ingest.linked_alias) {
      columns.push({
        title: _thatIntl.formatMessage({ id: "total-stored-events" }),
        dataIndex: "rows",
        key: "rows",
        render: rows => (rows && rows.toLocaleString()) || 0,
      })
    }

    columns.push({
      title: _thatIntl.formatMessage({ id: "tags" }),
      dataIndex: "tags",
      key: "tags",
      render: tags => this._renderTags(tags),
    })

    if (this.state.ingest && this.state.ingest.type === "BATCH") {
      columns.push({
        title: _thatIntl.formatMessage({ id: "actions" }),
        dataIndex: "actions",
        key: "actions",
        width: 120,
        render: (value, ingest) => {
          return (
            <IngestActionsCell
              statusChangeDisabled={
                ingestIsCreating(ingest) ||
                !checkPermissions(["status:write"]) ||
                (this.state.ingest &&
                  this.state.ingest.linked_with &&
                  this.state.linkedWithIngestActive === null)
              }
              deleteDisabled={
                ingestIsCreating(ingest) ||
                !checkPermissions(["ingest:delete"]) ||
                (this.state.ingest &&
                  this.state.ingest.linked_with &&
                  this.state.linkedWithIngestActive === null)
              }
              onDeleteClick={this._onDeleteIngestClick}
              onStatusChange={this._changeIngestStatus}
              active={ingestIsActive(ingest)}
            />
          )
        },
      })
    } else {
      columns.push({
        title: _thatIntl.formatMessage({ id: "actions" }),
        dataIndex: "actions",
        key: "actions",
        width: 120,
        render: (value, ingest) => {
          return (
            <IngestActionsCell
              statusChangeHidden={true}
              deleteDisabled={
                ingestIsCreating(ingest) || !checkPermissions(["ingest:delete"])
              }
              onDeleteClick={this._onDeleteIngestClick}
              onStatusChange={this._changeIngestStatus}
              active={ingestIsActive(ingest)}
            />
          )
        },
      })
    }

    return columns
  }

  get columns() {
    return ["REAL_TIME", "NEAR_REAL_TIME"].includes(this._getIngestType())
      ? this.RT_HEADER_COLUMNS
      : this.BATCH_HEADER_COLUMNS
  }

  _updateLastUpdateDate() {
    this.setState({ lastUpdate: moment().format("HH:mm:ss") })
  }

  _clearInterval() {
    if (this.interval !== null) {
      clearInterval(this.interval)
    }
  }

  _abortInFlightRequest() {
    if (this.controller !== null) {
      this.controller.abort()
    }
  }

  _fetchLinkedWithIngest(linked_with) {
    if (linked_with) {
      return authFetch(`${process.env.GATSBY_CONF_API_URL}/ingests`, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
        .then(response => response.json())
        .then(ingests =>
          ingests.find(
            ({ alias, version = "" }) => `${alias}:${version}` === linked_with
          )
        )
        .then(ingest => {
          if (ingest) {
            this.setState({ linkedWithIngestActive: ingestIsActive(ingest) })
          } else {
            this.setState({ linkedWithIngestActive: false })
          }
        })
    } else {
      return Promise.resolve()
    }
  }

  _fetchIngestPeriodically() {
    this.controller = new AbortController()
    const signal = this.controller.signal

    this.interval = setInterval(() => {
      getIngest(this.state.ingestIdUrlParam, { signal })
        .then(response => this._onIngestResponse(response))
        .then(_ => this._fetchStatistics())
    }, ONE_MIN_IN_MS)
  }

  _fetchIngest() {
    if (this.state.ingestIdUrlParam) {
      return getIngest(this.state.ingestIdUrlParam).then(response =>
        this._onIngestResponse(response)
      )
    } else {
      console.error('No "id" retrieved from URL in order to query it.')
    }
  }

  _shouldLeaveStatisticsOut() {
    return (
      this.state.ingest &&
      this.state.ingest.linked_alias &&
      !IngestDetail._ingestIsBatchType(this.state.ingest)
    )
  }

  _fetchStatistics() {
    if (this._shouldLeaveStatisticsOut()) {
      return Promise.resolve()
    } else {
      return authFetch(
        `${process.env.GATSBY_CONF_API_URL}/ingests/${this.state.ingestIdUrlParam}/statistics`,
        {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        }
      )
        .then(response => this._onStatisticsResponse(response))
        .then(_ => this.setState({ loading: false }))
        .catch(error => {
          console.error(`[ERROR]: Fetching statistics ${error}`)
        })
    }
  }

  _fetchCredentials() {
    return authFetch(this._getCertificatesEndpoint(), {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: {
        id: this.state.ingestIdUrlParam,
      },
    })
      .then(response => this._onCertificatesResponse(response))
      .then(_ => this.setState({ loading: false }))
      .catch(error => {
        console.error(`[ERROR]: Fetching credentials / certificates ${error}`)
      })
  }

  _onIngestResponse(ingest) {
    this.setState({ ingest }, () => {
      this._fetchLinkedWithIngest(ingest && ingest.linked_with)
      this._updateLastUpdateDate()
    })
  }

  _onStatisticsResponse(response) {
    const { status } = response
    if (status === 200) {
      return response.json().then(response => {
        const type = this._getIngestType()
        let statistics
        if (["REAL_TIME", "NEAR_REAL_TIME"].includes(type)) {
          const { historic = [], current } = response
          const orderedHistoric = historic.sort((a, b) =>
            moment(a.activationDate).isBefore(moment(b.activationDate)) ? 1 : -1
          )
          statistics =
            Object.keys(current).length > 0
              ? [current, ...orderedHistoric]
              : orderedHistoric
        } else {
          // BATCH & METADATA
          statistics =
            Array.isArray(response) &&
            response.sort((a, b) =>
              moment(a.startedAt).isBefore(moment(b.startedAt)) ? 1 : -1
            )
        }
        this.setState({ statistics })
      })
    } else {
      console.error(
        `[ERROR] fetching '/statistics'. Code: ${status}\n${(response &&
          response.message) ||
          response}`
      )
    }
  }

  _onCertificatesResponse(response) {
    const { status } = response

    if (status === 200) {
      return response.json().then(response => {
        const credentials = this._onParseCertificatesResponse(response)

        this.setState({ credentials })
      })
    } else {
      console.error(
        `[ERROR] fetching '/${this._getCertificatesEndpoint()}'. Code: ${status}\n${(response &&
          response.message) ||
          response}`
      )
    }
  }

  _onParseCertificatesResponse(response) {
    switch (this._getIngestType()) {
      case "REAL_TIME":
        return response
      case "BATCH":
      case "METADATA":
        const { credential_url, sftp_endpoint, sftp_user } = response

        return { credential_url, sftp_endpoint, sftp_user }
      case "NEAR_REAL_TIME":
        const { BrokerUser, BrokerPass, BrokerQueue, MqttEndpoints } = response

        return { BrokerUser, BrokerPass, BrokerQueue, MqttEndpoints }
    }
  }

  _getCertificatesEndpoint() {
    let endpoint = `${process.env.GATSBY_CONF_API_URL}/`

    switch (this._getIngestType()) {
      case "REAL_TIME":
        endpoint += "certificates"
        break
      case "BATCH":
      case "METADATA":
        endpoint += "credentials"
        break
      case "NEAR_REAL_TIME":
        endpoint += "nrt-credentials"
        break
    }

    return endpoint
  }

  _getIngestType() {
    return this.state.ingest ? this.state.ingest.type : ""
  }

  _deleteIngest() {
    return authFetch(
      `${process.env.GATSBY_CONF_API_URL}/ingests/${this.state.ingestIdUrlParam}`,
      {
        method: "DELETE",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      }
    )
  }

  _onSearchInputChange(ev) {
    const searchValue = (ev.target.value || "").trimStart().trimEnd()

    this.setState({ searchValue })
  }

  _filterStatistics(statistics = [], searchValue) {
    let filteredStatistics = []

    if (Array.isArray(statistics) && statistics.length > 0) {
      if (searchValue) {
        filteredStatistics = statistics.filter(
          ({ filename }) =>
            filename.toLowerCase().indexOf(searchValue.toLowerCase()) >= 0
        )
      } else {
        filteredStatistics = statistics.slice()
      }
    }

    return filteredStatistics
  }

  _navigateToIngestVersion(versionId) {
    navigate(`/ingest/${versionId}`)
  }

  _onDeleteIngestConfirm() {
    this._abortInFlightRequest()
    this._clearInterval()

    this._deleteIngest()
      .then(response => this._deleteIngestOkCallback(response))
      .catch(error => this._deleteIngestErrorCallback(error))
  }

  _onDeleteIngestCancel() {
    message.info(this.props.intl.formatMessage({ id: "modal-delete-cancel" }))
  }

  _onDeleteIngestClick() {
    const _thatIntl = this.props.intl
    const content = this._getModalContent()

    confirm({
      title: _thatIntl.formatMessage({ id: "modal-are-you-sure" }),
      content: content,
      okText: _thatIntl.formatMessage({ id: "modal-ok" }),
      okType: "danger",
      cancelText: _thatIntl.formatMessage({ id: "modal-ko" }),
      onOk: () => this._onDeleteIngestConfirm(),
      onCancel: () => this._onDeleteIngestCancel(),
    })
  }

  _deleteIngestOkCallback(response) {
    const { status } = response
    const { ingest } = this.state

    if (status === 200) {
      message.info(
        <span>
          <strong>
            {this.state.ingest.alias}
            {this.props.intl.formatMessage({
              id: "page-ingestion-detail-remove-ok-text-first",
            })}
          </strong>
          <br />
          {this.props.intl.formatMessage({
            id: "page-ingestion-detail-remove-ok-text-second",
          })}
        </span>
      )
      if (
        ingest &&
        Array.isArray(ingest.versions) &&
        ingest.versions.length === 1
      ) {
        setTimeout(
          () =>
            navigate("/ingest", {
              state: { deletedIngestId: this.state.ingest.id },
            }),
          1500
        )
      } else {
        const version = ingest.versions
          .filter(version => version.id !== ingest.id)
          .pop()

        setTimeout(() => navigate(`/ingest/${version.id}`), 1500)
      }
    } else {
      if (status === 400) {
        response.json().then(responseJson => {
          if (responseJson.message === "DELETE_ERROR_INGEST_LINKED") {
            message.error(
              this.props.intl.formatMessage({
                id: "page-ingestions-delete-ingestion-ko-ingestion-linked",
              })
            )
          } else {
            message.error(
              `${this.props.intl.formatMessage({
                id: "page-ingestions-delete-ingestion-ko",
              })} Status Code: ${status}`
            )
          }
        })
      } else {
        message.error(
          this.props.intl.formatMessage({
            id: "page-ingestion-detail-remove-ko-text",
          })
        )
      }

      this._fetchIngestPeriodically()
    }
  }

  _deleteIngestErrorCallback() {
    message.error(
      this.props.intl.formatMessage({
        id: "page-ingestion-detail-remove-ko-text",
      })
    )
  }

  _getModalContent() {
    const { ingest } = this.state

    if (ingest && ingest.versions && ingest.versions.length === 1) {
      return this.props.intl.formatMessage(
        {
          id: "page-ingestion-detail-remove-ingest-alert-text",
        },
        {
          alias: ingest.alias,
        }
      )
    } else {
      return this.props.intl.formatMessage(
        {
          id: "page-ingestion-detail-remove-version-alert-text",
        },
        {
          alias: ingest.alias,
          version: ingest.version,
        }
      )
    }
  }

  _createNewDataSource() {
    navigate(`/ingest/options?linked_with=${this.state.ingestIdUrlParam}`)
  }

  _createNewIngestVersion() {
    navigate(
      `/ingest/new?t=${this.state.ingest.type}&id=${this.state.ingestIdUrlParam}`
    )
  }

  _changeIngestStatus() {
    const ingest = this.state.ingest
    const active = ingestIsActive(ingest)

    if (this.state.linkedWithIngestActive && active) {
      const _formatMessage = this.props.intl.formatMessage

      warning({
        title: _formatMessage({
          id: "ingestion-could-not-deactivate-title",
        }),
        content: _formatMessage(
          { id: "ingestion-could-not-deactivate-content" },
          { ingestionId: this.state.ingest && this.state.ingest.linked_with }
        ),
      })
    } else {
      this._abortInFlightRequest()
      this._clearInterval()

      const { id, alias } = ingest
      const status = active ? "INGEST_STATUS_DISABLED" : "INGEST_STATUS_ENABLED"

      // Make a copy of the actual state of the ingests
      const previousStatus = ingest.status

      ingest.status = status
      // Make the change sattus visible to the user
      this.setState({ ingest })

      authFetch(`${process.env.GATSBY_CONF_API_URL}/ingests/${id}/status`, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        method: "PUT",
        body: { status },
      }).then(response => {
        if (response.status !== 200) {
          ingest.status = previousStatus
          this.setState({ ingest })

          console.error(
            `[ERROR]: Changing ingest "${alias}" status.\nStatus Code: ${status}\n${response}`
          )
          message.error(
            this.props.intl.formatMessage({
              id: "page-ingestions-change-ingestion-status-ko",
            })
          )
        } else {
          const key =
            previousStatus === "INGEST_STATUS_ENABLED"
              ? "deactivated"
              : "activated"

          message.success(
            this.props.intl.formatMessage(
              {
                id: `page-ingestion-detail-${key}-successfully`,
              },
              {
                alias,
              }
            )
          )
        }

        this._fetchIngestPeriodically()
      })
    }
  }

  _renderTags(tags = []) {
    return Array.isArray(tags) && tags.length > 0 ? (
      <div className="ingest-tags">
        {tags.map(tag => (
          <IngestTag key={`tag-${tag}`}>{tag}</IngestTag>
        ))}
      </div>
    ) : null
  }

  render() {
    const { credentials = {} } = this.state
    const _thatIntl = this.props.intl

    return (
      <Template selected={["ingest", "ingestion-index"]}>
        <div>
          <div
            style={{
              display: "flex",
              alignItems: "baseline",
              justifyContent: "space-between",
            }}
          >
            <CustomBreadcrumb
              style={{ flexGrow: "1" }}
              crumbs={[
                {
                  label: _thatIntl.formatMessage({
                    id: "menu-ingestions-management",
                  }),
                  link: "/ingest",
                },
                `${_thatIntl.formatMessage({
                  id: "page-ingestion-breadcrumb-detail-of",
                })} ${this.state.ingest.alias || ""}`,
              ]}
            />
            {this.state.lastUpdate !== null ? (
              <div>
                {this.props.intl.formatMessage({ id: "last-update" })}:{" "}
                {this.state.lastUpdate}
              </div>
            ) : null}
          </div>
          <Row>
            <Col className="knolar-intro" span={12}>
              {this.props.intl.formatMessage({ id: "page-ingestions-title" })}
            </Col>
            <Col
              span={3}
              offset={9}
              align="left"
              style={{
                display: "flex",
                justifyContent: "flex-end",
                alignItems: "center",
              }}
            >
              {!IngestDetail._isEmptyObject(this.state.ingest) &&
              this.state.ingest.type !== INGEST_TYPES.metadata ? (
                <Form.Item
                  style={{ fontSize: 12, marginBottom: 10 }}
                  htmlFor="select-versions"
                  layout={"horizontal"}
                  label={this.props.intl.formatMessage({
                    id: "versions",
                  })}
                >
                  <Select
                    id="select-versions"
                    name="versions"
                    className="knolar-select"
                    value={this.state.ingest.version}
                    onChange={this._navigateToIngestVersion}
                    style={{ width: "200px" }}
                  >
                    {this.state.ingest.versions.map(p => (
                      <Select.Option key={p.id} value={p.id}>
                        {" "}
                        {p.version}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              ) : null}
            </Col>
          </Row>
          <div>
            {!IngestDetail._isEmptyObject(this.state.ingest) ? (
              <Table
                rowKey="id"
                columns={this.columns}
                dataSource={[this.state.ingest]}
                className="ingest-detail-header-table"
                pagination={false}
              />
            ) : null}
          </div>
        </div>
        <div
          className="content"
          style={{
            padding: "20px",
            backgroundColor: "#f7f9fa",
            boxShadow: "0px -10px 15px #0000001A",
          }}
        >
          <Row>
            {this.state.loading ? (
              <Row
                style={{
                  height: "200px",
                  width: "100%",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Spin size="large" />
              </Row>
            ) : (
              <Row>
                {this.state.ingest &&
                this.state.ingest.id &&
                checkPermissions(["ingest:write"]) ? (
                  <div
                    className="buttons"
                    style={{
                      position: "absolute",
                      right: "-20px",
                      top: "-30px",
                      zIndex: 2,
                    }}
                  >
                    {this.state.ingest.type !== INGEST_TYPES.metadata && (
                      <Button
                        style={{ marginRight: 25, marginTop: 30 }}
                        className="knolar-button"
                        type="primary"
                        onClick={this._createNewIngestVersion}
                        disabled={
                          this.state.ingest && this.state.ingest.linked_with
                        }
                      >
                        {this.props.intl.formatMessage({
                          id: "page-ingestion-detail-create-new-version",
                        })}
                      </Button>
                    )}
                    <Button
                      style={{ marginRight: 25, marginTop: 30 }}
                      className="knolar-button"
                      type="primary"
                      onClick={this._createNewDataSource}
                      disabled={
                        (this.state.ingest && this.state.ingest.linked_with) ||
                        !ingestIsActive(this.state.ingest)
                      }
                    >
                      {this.props.intl.formatMessage({
                        id: "page-ingestion-detail-create-new-data-source",
                      })}
                    </Button>
                  </div>
                ) : null}
                <Tabs
                  className="ingests-tabs"
                  defaultActiveKey={
                    this._shouldLeaveStatisticsOut() ? "2" : "1"
                  }
                >
                  {this._shouldLeaveStatisticsOut() ? null : (
                    <TabPane
                      tab={this.props.intl.formatMessage({
                        id: "page-ingestion-tab-statistics",
                      })}
                      key="1"
                      forceRender={true}
                    >
                      {(IngestDetail._ingestIsBatchType(this.state.ingest) ||
                        IngestDetail._ingestIsMetadataType(
                          this.state.ingest
                        )) &&
                      Array.isArray(this.state.statistics) &&
                      this.state.statistics.length > 0 ? (
                        <Row
                          style={{
                            display: "flex",
                            justifyContent: "flex-start",
                            paddingLeft: "30px",
                            margin: "30px 0",
                          }}
                        >
                          <Search
                            className="ingests-header-search"
                            placeholder={this.props.intl.formatMessage({
                              id: "page-ingestion-search-files",
                            })}
                            onInput={this._onSearchInputChange}
                          />
                        </Row>
                      ) : null}
                      <IngestStatisticsTable
                        isRealTimeIngest={[
                          "REAL_TIME",
                          "NEAR_REAL_TIME",
                        ].includes(this.state.ingest.type)}
                        statistics={this._filterStatistics(
                          this.state.statistics,
                          this.state.searchValue
                        )}
                      />
                    </TabPane>
                  )}
                  <TabPane
                    tab={this.props.intl.formatMessage({
                      id: "page-ingestion-tab-detail",
                    })}
                    key="2"
                  >
                    <IngestDetailTable
                      ingest={this.state.ingest}
                      credentials={credentials}
                    />
                  </TabPane>
                </Tabs>
              </Row>
            )}
          </Row>
        </div>
      </Template>
    )
  }
}

export default injectIntl(withAuth(IngestDetail))
